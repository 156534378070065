.single-training img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    max-height: calc(100vh - 100px);
    text-align: center;
    display: block;
}

.single-training video {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    max-height: calc(100vh - 100px);
    text-align: center;
    display: block;
    box-shadow: none !important;
    filter: none !important;
    
}

/* .single-training video::-webkit-media-controls-panel {
    box-shadow: none !important;
    background: transparent !important; 
}

.single-training video::-webkit-media-controls {
    box-shadow: none !important;
}
 */

.single-training table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
}

.single-training table th {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.single-training table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}