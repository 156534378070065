.custom-markdown table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
}

.custom-markdown th, .custom-markdown td {
    text-align: left;
    padding: 6px;
    border: 1px solid #ddd;
}

.custom-markdown img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}

.custom-morkdown-section {
    margin-bottom: 20px;
}